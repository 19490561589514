import React from 'react';
import styled from 'styled-components';

import { useJourneyContext } from '../Contexts/JourneyContext/JourneyContext';
import useText from '../../hooks/data/useText';

const SiteFooter = ({ footerTandC }) => {
  const text = useText();
  const journeyContext = useJourneyContext();

  return (
    <Footer className="footer">
      <div className="container pt-5">
        <P className="text-grey">
          {text.footerPartA}{text.footerPartB}
          {footerTandC}
        </P>
        
        { text.footerImageUrl && <img src={text.footerImageUrl} alt={text.footerImageAlt} className="pt-4" /> }
      </div>
    </Footer>
  );
}

const Footer = styled.footer`
  padding-bottom: ${props => props.theme.spacing.base * 40}px
`;

const P = styled.p`
  color: ${props => props.theme.colors.greyDark}
`;


export default SiteFooter;