import React from 'react';
import styled from 'styled-components';
import { formatGBP } from '../../../utils/formatters';


import Card from '../../Card';
import ProgressBar from './ProgressBar';

const calcBarPerc = (amount, max) => (amount / max) * 100;

const FinancialSummary = ({ summaries, answers }) => {
  const { rentAmount, utilitiesAmount, travelAmount, tvAndTelecomsAmount, payingMonthlyAmount,
    childcareAmount, groceryAmount, dayToDayLivingAmount, otherDebtsAmount } = answers;

  const outgoingSummary = summaries.find(summary => summary.type === 'totalOutgoing');
  const incomeSummary = summaries.find(summary => summary.type === 'totalIncoming');
  const differenceSummary = summaries.find(summary => summary.type === 'monthlyDifference');

  // calculations for bars lifted from DAC.
  let highestOutgoing = Math.max(...[rentAmount, utilitiesAmount, travelAmount, tvAndTelecomsAmount,
     payingMonthlyAmount, childcareAmount, groceryAmount, dayToDayLivingAmount, otherDebtsAmount]);

  return (
    <div className="container p-4 px-lg-0">

      <h5>Your monthly summary</h5>

      <Card className="mt-4 mt-lg-5 p-lg-4">

        <div className="d-flex justify-content-between">
          <p className="text-medium">{incomeSummary.description}</p>
          <span className="font-weight-bold text-medium">{formatGBP(incomeSummary.value)}</span>
        </div>

        <hr className="mt-0" />

        <div className="d-flex justify-content-between">
          <p className="text-medium">{outgoingSummary.description}</p>
          <span className="font-weight-bold text-medium">{formatGBP(outgoingSummary.value)}</span>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="mt-4">
              <div className="d-flex justify-content-between">
                <p className="font-weight-medium mb-1 text-medium">Mortgage/Rent</p>
                <span className="text-medium">{formatGBP(rentAmount)}</span>
              </div>
              <ProgressBar color={"blue"} fillPerc={calcBarPerc(rentAmount, highestOutgoing)} />
            </div>

            <div className=" mt-4">
              <div className="d-flex justify-content-between">
                <p className="font-weight-medium mb-1 text-medium">Household bills</p>
                <span className="text-medium">{formatGBP(utilitiesAmount)}</span>
              </div>
              <ProgressBar color={"blueDark"} fillPerc={calcBarPerc(utilitiesAmount, highestOutgoing)} />
            </div>

            <div className=" mt-4">
              <div className="d-flex justify-content-between">
                <p className="font-weight-medium mb-1 text-medium">Travel</p>
                <span className="text-medium">{formatGBP(travelAmount)}</span>
              </div>
              <ProgressBar color={"green"} fillPerc={calcBarPerc(travelAmount, highestOutgoing)} />
            </div>

            <div className=" mt-4 ">
              <div className="d-flex justify-content-between">
                <p className="font-weight-medium mb-1 text-medium">TV, Internet &amp; phone</p>
                <span className="text-medium">{formatGBP(tvAndTelecomsAmount)}</span>
              </div>
              <ProgressBar color={"purple"} fillPerc={calcBarPerc(tvAndTelecomsAmount, highestOutgoing)} />
            </div>

            <div className=" mt-4">
              <div className="d-flex justify-content-between">
                <p className="font-weight-medium mb-1 text-medium">Debt payment</p>
                <span className="text-medium">{formatGBP(payingMonthlyAmount)}</span>
              </div>
              <ProgressBar color={"red"} fillPerc={calcBarPerc(payingMonthlyAmount, highestOutgoing)} />
            </div>

          </div>
          <div className="col-md-6">
            <div className=" mt-4 mt-lg-4">
              <div className="d-flex justify-content-between">
                <p className="font-weight-medium mb-1 text-medium">Childcare</p>
                <span className="text-medium">{formatGBP(childcareAmount)}</span>
              </div>
              <ProgressBar color={"grey1"} fillPerc={calcBarPerc(childcareAmount, highestOutgoing)} />
            </div>

            <div className=" mt-4">
              <div className="d-flex justify-content-between">
                <p className="font-weight-medium mb-1 text-medium">Shopping</p>
                <span className="text-medium">{formatGBP(groceryAmount)}</span>
              </div>
              <ProgressBar color={"pink"} fillPerc={calcBarPerc(groceryAmount, highestOutgoing)} />
            </div>

            <div className=" mt-4">
              <div className="d-flex justify-content-between">
                <p className="font-weight-medium mb-1 text-medium">Day-to-Day living</p>
                <span className="text-medium">{formatGBP(dayToDayLivingAmount)}</span>
              </div>
              <ProgressBar color={"teal"} fillPerc={calcBarPerc(dayToDayLivingAmount, highestOutgoing)} />
            </div>

            <div className=" mt-4">
              <div className="d-flex justify-content-between">
                <p className="font-weight-medium mb-1 text-medium">Other debt</p>
                <span className="text-medium">{formatGBP(otherDebtsAmount)}</span>
              </div>
              <ProgressBar color={"cyan"} fillPerc={calcBarPerc(otherDebtsAmount, highestOutgoing)} />
            </div>

          </div>
        </div>
        <hr className="mt-4" />
        <div className="d-flex justify-content-between">
          <p className="text-medium">Monthly - £</p>
          <span className="font-weight-bold text-medium">{formatGBP(differenceSummary.value)}</span>
        </div>

        {differenceSummary.value <= 0 ? (
          <Badge>
            <h5>It looks like you're spending more than you can afford!</h5>
            <p><span className="font-weight-medium">Don't worry </span> - there's help on hand. It's always a good idea to regularly review your spending and see if you can make cutbacks. We've listed some options below for you to consider.</p>
          </Badge>
        ) :
        (
          <Badge>
            <h5>It looks like you're managing well right now, even if it doesn't always feel that way.</h5>
            <p>It's always a good idea to regularly review your spending and see if you can make cutbacks. We've listed some options below for you to consider.</p>
          </Badge>
        )}
      </Card>

    </div>
  );
}

export default FinancialSummary;

const Badge = styled.div`
  padding:25px 30px 18px;
  background-color:${props => props.theme.colors.grey4};
  border-radius:5px;
  text-align:left;

.font-weight-medium{
  font-weight: 500; 
}



`;