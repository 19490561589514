import {useStaticQuery, graphql} from 'gatsby'

const useText = () => {
  const data = useStaticQuery(graphql`
    query CompleteText {
      allCompleteJson {
        nodes {
          header
          callFrom
          subheader
          getReportBtn
          reportTitle
          reportDescription
          reportAmberTitle
          reportRedTitle
          reportGreenTitle
          footerPartA
          footerPartB
          footerImageUrl
          footerImageAlt
        }
      }
    }
  `);
  return data?.allCompleteJson?.nodes?.[0] ?? {};
};

export default useText;
