import React, { useState } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import SiteFooter from './SiteFooter';
import ContactUsPanel from './ContactUsPanel';
import FinancialSummary from './FinancialSummary';
import WhySolution from './WhySolution';
import useText from '../../hooks/data/useText';


const Report = ({ report }) => {
  const texts = useText()
  const data = useStaticQuery(graphql`
    query SolutionsQuery {
      allStoriesJson {
        nodes {
          image
          displayName
          solution
        }
      }
      allSolutionsJson {
        nodes {
          displayName
          name
          abbreviation
          article
          story
          footerTandC
          pros {
            order
            value
          }
          cons {
            order
            value
          }
          howWeWork {
            order
            value
          }
          whatHappens{
              order
              icon
              heading
              title
          }
        }
      }
    }
  `);
  // TODO: switch to manual tabs to remove the need for this workaround.
  const [selectedOption, setSelectedOption ] =  useState(0);

  let scores = data.allSolutionsJson.nodes.map(solution => ({
    ...solution,
    score: report?.scores[solution.name],
    story: data.allStoriesJson.nodes.find(el => el.solution === solution.name)
  }));


  // workaround added to force iva to always be at the top, this should probably be added as a solution 
  // precedence later to make it configurable.
  const greenOptions = ivaToTheTop(scores.filter(solution => solution.score === 'Green'));
  const amberOptions = ivaToTheTop(scores.filter(solution => solution.score === 'Amber'));
  const redOptions = ivaToTheTop(scores.filter(solution => solution.score === 'RedUnlikely'));
  
  const footerTandCappends = [...greenOptions, ...amberOptions, ...redOptions][selectedOption]?.footerTandC;

  return (
    <div>

      <div className="bg-grey pb-5">
        <div className="container p-4 px-lg-0">
          <h5 className="font-weight-bold">{texts.reportTitle}</h5>
          <p className="mb-4 mb-md-5">{texts.reportDescription}</p>

          <SolutionsPanel>

            <Tabs onSelect={index => setSelectedOption(index)}>

              <TabList>

                { // Green options // TODO: all of the options can be combined into 1 component
                  greenOptions.length > 0 && greenOptions.map(option => (
                    <Tab
                      key={`tab-${option.displayName}`}
                      tabIndex="0"
                      className="solutions_likely fwg-box-shadow">
                      <p>{option.displayName}</p>
                    </Tab>
                  ))
                }

                { amberOptions.length > 0 && (
                  <>
                  <h5 className="font-weight-bold">{texts.reportAmberTitle}</h5>
                  { // Amber options
                    amberOptions.map(option => (
                      <Tab
                        key={`tab-${option.displayName}`}
                        tabIndex="0"
                        className="solutions_amber fwg-box-shadow">
                        <p>{option.displayName}</p>
                      </Tab>
                    ))
                  }
                  </>
                )}

                { redOptions.length > 0 && (
                  <>
                    <h5 className="font-weight-bold">{texts.reportRedTitle}</h5>
                    { // Red options
                      redOptions.length && redOptions.map(option => (
                        <Tab 
                          key={`tab-${option.displayName}`}
                          tabIndex="0"
                          className="solutions_unlikely fwg-box-shadow">
                          <p>{option.displayName}</p>
                        </Tab>
                      ))
                    }
                  </>
                )}
              </TabList>

                {[...greenOptions, ...amberOptions, ...redOptions].map(option => (
                  <TabPanel key={`tabpanel-${option.displayName}`}>
                    <div className="text-center">
                      <WhySolution solution={option} />
                    </div>
                  </TabPanel>
                ))}

            </Tabs>

          </SolutionsPanel>
        </div>
      </div>
      <div className="bg-white py-5">
        <FinancialSummary summaries={report.summaries} answers={report.answers}/>
      </div>

      <ContactUsPanel callCentrePhoneNumber={report.callCentrePhoneNumber} />
      

      
          
      <SiteFooter
        footerTandC={footerTandCappends}
      />


    </div>
  );
}


const SolutionsPanel = styled.div`
  position: relative;
  display: block;

  @media (min-width: 992px) {
    &::before {
        content: '';
        border-left: 2px solid  ${props => props.theme.colors.gray1};
        height: 100%;
        position: absolute;
        left: 410px;
    }
  }

  /* Put the below in here for now but it doesnt really belong*/
  
  // REACT TABS STUFF
  .react-tabs__tab-list {
    margin: 0;
    padding: 0;
  }

  .react-tabs__tab-panel { display: none; }
  
  .react-tabs__tab-panel--selected { display: block; }

  .react-tabs__tab--selected { padding-left: 21px; }
  
  .react-tabs__tab--selected {
    color:  ${props => props.theme.colors.white};
    display: block;
  }
  
  @media (min-width: 992px) {
    .react-tabs { display:flex; }
    .react-tabs__tab-list {
      display: flex;
      flex-direction: column;
    }
    .react-tabs__tab-panel { margin-left: 170px; }
  }


  /* Solutions */
  .solutions_likely, .solutions_unlikely, .solutions_amber {
    width: 315px;
    height:62px;
    margin-bottom: 20px;
    padding:20px;
    background:  ${props => props.theme.colors.white};
    border-radius:8px;
    list-style: none;
    align-items:center;
    cursor: pointer;
    font-size:18px;
  }

  .solutions_likely { border-left: 8px solid  ${props => props.theme.colors.green}; }

  .solutions_amber { border-left: 8px solid  ${props => props.theme.colors.orange}; }

  .solutions_unlikely { border-left: 8px solid  ${props => props.theme.colors.red}; }



  .solutions_likely.react-tabs__tab--selected {
    background:  ${props => props.theme.colors.green};
    border-left: 8px solid  ${props => props.theme.colors.greenDark};
  }
  
  .solutions_amber.react-tabs__tab--selected {
    background:  ${props => props.theme.colors.orange};
    border-left: 8px solid  ${props => props.theme.colors.orangeDark};
  }

  .solutions_unlikely.react-tabs__tab--selected {
    background:  ${props => props.theme.colors.red};
    border-left: 8px solid  ${props => props.theme.colors.redDark};
  }

`;


export default Report;


function ivaToTheTop(options) {
  if(options.length > 1) {
    return options.sort((x,y) => { return x.name == 'iva' ? -1 : y.name == 'iva' ? 1 : 0; })
  }
  return options;
}