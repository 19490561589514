import React from 'react';
import styled from 'styled-components';
import Card from '../Card/'

// TODO: this needs breaking down, the spinning animation etc doesnt really need to be part of this
const Loader = ({ message }) => (
  <Card>
    <Spinner>
      <div /><div /><div /><div /><div /><div /><div /><div />
    </Spinner>
    <SpinnerMessage>{message}</SpinnerMessage>
  </Card>
);

const SpinnerMessage = styled.p`
    color: ${props => props.theme.colors.green};
    margin-top:20px;
`;


const Spinner = styled.div`
  color: ${props => props.theme.colors.green};
  display: inline-block;
  width: 50px;
  height: 50px;
  margin:0 auto;
  position: relative;
  transition: all 0.5s;
  
  div {
    transform-origin: 30px 30px;
    animation: fwg-spinner 1.2s linear infinite;
  }

  div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 28px;
    width: 3px;
    height: 12px;
    border-radius: 30%;
    background: #53c200;
  }
  // TODO: styled-components will allow us to reduce this code and make it customisable fairly easily
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }

  div:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -1s;
  }

  div:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.9s;
  }

  div:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.8s;
  }

  div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.7s;
  }

  div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.6s;
  }

  div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.5s;
  }

  div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: -0.4s;
  }

  @keyframes fwg-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
  }
`;




export default Loader;