import styled from 'styled-components';


// TODO: relies on .btn from bootstrap so we've created work to pull that out for now.
const Button = styled.a`
  min-width: 220px;
  min-height: 42px;
  max-width: 320px;
  background-color: ${props => props.theme.colors.blue};
  border: 1px solid ${props => props.theme.colors.blue};
  color:  ${props => props.theme.colors.white};
`;

export default Button;