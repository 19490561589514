import styled from 'styled-components';


// TODO: relies on .btn from bootstrap so we've created work to pull that out for now.
const ButtonLinkGhost = styled.a`
  width:220px;
  height:42px;
  background-color: ${props => props.theme.colors.white};
  border:2px solid ${props => props.theme.colors.blue};
  color: ${props => props.theme.colors.blue};
`;

export default ButtonLinkGhost;