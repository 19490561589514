import React, { useState } from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby-link';

import { getReportById } from '../services/whitelabel-api';
import { isSSR } from '../utils/renderContext';

import { useJourneyContext } from '../components/Contexts/JourneyContext/JourneyContext';

import Report from '../components/Report';
import Loader from '../components/Loaders/Spinner';

const PageLoader = () => <Loader />;

const NO_REPORT_REDIRECT_LOCATION = "/";

const Advice = ({ location }) => {
  const [report, setReport] = useState(location?.state?.report);
  const journeyContext = useJourneyContext();
  
  if(isSSR()) return null;
  
  if(!report) {
    // check for encryptedTmRef
    const queryParams = queryString.parse(window.location.search);
    const encryptedTmRef =  queryParams?.id;
    if(!encryptedTmRef) navigate('/'); // no id boot to journey
    // call service with id
    getReportById(encryptedTmRef, journeyContext?.source?.appId).then((reportRes) => {
      setReport(reportRes?.data);
    })
    .catch(() => navigate(NO_REPORT_REDIRECT_LOCATION))
  }

  return <AdviceLayout report={report} />;
}

export const AdviceLayout = ({ report }) => report ? <Report report={report}/> : <PageLoader />;

export default Advice;