import React from 'react';
import styled from 'styled-components';

const ProgressBar = (props) => (
  <BarWrap>
    <InnerBar {...props} data-testid="ProgressBar_InnerBar"/>
  </BarWrap>
);

const BarWrap = styled.div`
    display: flex;
    overflow: hidden;
    line-height: 0;
    font-size: .75rem;
    background-color: #e9ecef;
    height: 8px;
    border-radius: 10px;
`;

const InnerBar = styled.div`
  border-radius: 10px;
  background-color:  ${props => props.theme.colors[props.color] || props.color};
  width: ${props => props.fillPerc}%
`;

export default ProgressBar;