import React from 'react';
import styled from 'styled-components';

import ButtonLink from '../Buttons/ButtonLink';

import { TickWhite } from '../svg/icons';
import background from '../../images/expert.png';

const ContactUsPanel = ({ callCentrePhoneNumber }) => (
  <Panel className="pb-5 py-md-5">
    <div className="container px-4 px-lg-0">
      <LeftCol className="col-md-6 pt-5 p-0">
        <h5 className="font-weight-bold">Talk to a debt expert</h5>
        <h5 className="py-4">Free and confidential advice</h5>
        <ul className="list-unstyled">
          <li className="d-flex align-items-center mb-3">
            <TickWhite className="align-self-center" />
            <span className="ml-2">Write off up to 81% of debt.</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <TickWhite className="align-self-center" />
            <span className="ml-2">Repay with one affordale monthly payment.</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <TickWhite className="align-self-center" />
            <span className="ml-2">Freeze interest and charges.</span>
          </li>
          <li className="d-flex align-items-center mb-3">
            <TickWhite className="align-self-center" />
            <span className="ml-2">Stop pressure from people and companies you owe money to.</span>
          </li>
        </ul>

        <ButtonLink href={`tel:${callCentrePhoneNumber}`} className="d-block btn pt-2 mt-5">Get Started</ButtonLink>

      </LeftCol>
    </div>
  </Panel>
);


const Panel = styled.div`
  position: relative;
  background-color:  ${props => props.theme.colors.blueDark};

  &::before { 
    // this is odd
    display: block;
    content: "";
    width: 100%;
    height: 155px;
    top: 0;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  @media (min-width: 992px) {
    min-height:510px;

    &::before {
      width: 50%;
      position:absolute;
      height: 100%;
      right: 0;
      background-size: 100% auto;
    }
  }
`;

const LeftCol = styled.div`
color:  ${props => props.theme.colors.white};

`;


export default ContactUsPanel;