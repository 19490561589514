import React from 'react';

import ButtonLink from '../Buttons/ButtonLink';
import ButtonLinkGhost from '../Buttons/ButtonLinkGhost';
import { Cross, Tick } from '../svg/icons';

const WhySolution = ({ solution }) => {
  const story = solution?.story;
  return (
    <div className="text-left">
      <h5>Why {solution?.abbreviation.charAt(0) === "I" ? "an" : "a"}  {solution?.displayName}?</h5>

      <p className="font-weight-bold my-3">Plus points</p>
      <ul className="list-unstyled">
        {solution?.pros?.map(pro => (
          <li key={`${solution.name}-pro-${pro.order}`} className="d-flex mb-2">
            <Tick className="pt-1" /><span className="ml-2">{pro.value}</span>
          </li>
        ))}
      </ul>

      <p className="font-weight-bold my-3">Minus points</p>
      <ul className="list-unstyled">
        {solution?.cons?.map(con => (
          <li key={`${solution.name}-con-${con.order}`} className="d-flex mb-2">
            <Cross className="pt-1" /><span className="ml-2">{con.value}</span>
          </li>
        ))}
      </ul>
      {solution?.story ?
        <div className="solution_story d-flex mt-5 justify-content-center justify-content-md-start">
          <img width="90" height="90" className="align-self-center" src={`../../../${story.image}.png`} alt="Customer Story"></img>
          <div className="ml-3">
            <ButtonLink href={`/solutions/${solution?.name}`} className="btn d-block mb-2">Read about {solution?.abbreviation}</ButtonLink>
            <ButtonLinkGhost href={`/stories/${solution?.name}`} type="button" className="btn d-block">Read {story.displayName}'s story</ButtonLinkGhost>
          </div>
        </div>
        :
        <ButtonLink href={`/solutions/${solution?.name}`} className="btn d-block mt-4 mt-lg-5 mb-2">Read about {solution?.abbreviation}</ButtonLink>
      }
    </div>
  )
}



export default WhySolution;